import "../styles/main.scss";
import { useState } from "react";
import { SubscriptionProvider, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CandidateSidebar } from "./Components/CandidateSidebar";
import { CompanySidebar } from "./Components/CompanySidebar";
import { MoreMenu } from "./Components/MoreMenu";
import { useResponsiveDetailMenu } from '../hook/useResponsiveDetailMenu';
const greenArrowIcon = require("../image/icon/right-green-arrow_ic.svg");

const setActive = ({ location: { pathname } }, active) => {
  if (pathname === active) {
    return { className: "aside__top__link active" };
  }
};

export default function Root(props) {
  const [detailMenu, setDetailMenu] = useState(false);
  const { onAuthorizedRoutes } = useHasPermission();
  const isCandidate = onAuthorizedRoutes("candidate");
  const screenWidth = useResponsiveDetailMenu(detailMenu)

  return (
    <div className={`${detailMenu ? "aside__inner active" : "aside__inner"}`}>
      <div
        className="aside__toggle"
        onClick={() => {
          setDetailMenu(!detailMenu);
        }}
      >
        <img
          src={greenArrowIcon}
          alt="green arrow"
          className="aside__toggle__icon"
        />
      </div>
      {isCandidate ? (
        <CandidateSidebar setActive={setActive} />
      ) : (
        <SubscriptionProvider>
          <CompanySidebar setActive={setActive} />
        </SubscriptionProvider>
      )}
      <MoreMenu />
    </div>
  );
}
