import { useState, useEffect } from "react";

export const useResponsiveDetailMenu = (detailMenu) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateScreenSize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", updateScreenSize);
        return () => {
            window.removeEventListener("resize", updateScreenSize);
        };
    }, []);

    useEffect(() => {
        const updateAppInnerClasses = () => {
            const appElements = document.querySelectorAll(".app__inner");

            appElements.forEach((element) => {
                if (screenWidth > 770) {
                    element.classList.toggle("open", detailMenu);
                } else {
                    element.classList.remove("open");
                }
            });
        };

        updateAppInnerClasses();

        const observer = new MutationObserver(() => {
            updateAppInnerClasses();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: false, 
        });

        return () => {
            observer.disconnect(); 
        };
    }, [screenWidth, detailMenu]);

    return screenWidth;
};

